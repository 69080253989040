import { Box, Divider, Drawer, List, ListItem, Typography, Button, Link, makeStyles } from '@material-ui/core'
import * as React from 'react'
import CloseIcon from '@material-ui/icons/Close'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import NotificationRow from './NotificationRow'
import { useGetActivityQuery, useMarkAllAsReadMutation } from 'store/modules/activity/activity.query'
import { getToken } from 'utils/localStorage/token'
import { Link as RouterLink } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { SearchInput } from 'app/components/SearchInput/SearchInput'

type Anchor = 'right'

const useStyles = makeStyles(theme => ({
    notificationsDrawer: {
        top: '93px !important',
        '& .MuiDrawer-paperAnchorRight': {
            top: '93px',
            minWidth: 480,
            [theme.breakpoints.down('sm')]: {
                mindWidth: 'auto',
                top: 0,
                left: 0,
                right: 0,
            },
        },
        '& .MuiBackdrop-root': {
            top: '93px',
        },
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: 24,
        paddingBottom: 24,
        paddingLeft: 32,
        paddingRight: 32,
        [theme.breakpoints.down('sm')]: {
            display: 'block',
        },
    },
    subheader: {
        padding: 16,
        paddingTop: 0,
    },
    footer: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 24,
        paddingBottom: 24,
        paddingLeft: 32,
        paddingRight: 32,
        cursor: 'pointer',
        [theme.breakpoints.down('sm')]: {
            paddingRight: 0,
        },
    },
    notificationsContainer: {
        width: 480,
        overflowY: 'scroll',
        height: 'calc(100% - 310px)',
        [theme.breakpoints.down('sm')]: {
            height: '100%',
            width: 'auto',
        },
    },
    iconContainer: {
        [theme.breakpoints.down('sm')]: {
            marginTop: 32,
            justifyContent: 'space-between',
        },
    },
}))

interface INotificationsDrawer {
    isOpen: boolean
    onChangeOpenDrawer: (open) => void
}

export default React.memo(function NotificationsDrawer(props: INotificationsDrawer) {
    const classes = useStyles()
    const access_token = getToken('access_token')
    const { enqueueSnackbar } = useSnackbar()

    const { data: activities, refetch } = useGetActivityQuery({}, { skip: !access_token })
    const [searched, setSearched] = React.useState<string>('')

    const [markAllAsRead] = useMarkAllAsReadMutation()

    let items = activities?.results ?? []

    const requestSearch = (searchedVal: string) => {
        setSearched(searchedVal)
    }

    const cancelSearch = () => {
        setSearched('')
    }

    if (searched) {
        const filteredRows = items.filter(row => {
            return (
                // Add search by title
                row?.title?.toLowerCase().includes(searched.toLowerCase()) ||
                row?.action_object?.path?.toLowerCase().includes(searched.toLowerCase()) ||
                row?.action_object?.name?.toLowerCase().includes(searched.toLowerCase())
            )
        })
        items = filteredRows
    }

    const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
            return
        }
        props.onChangeOpenDrawer(open)
    }

    const submitMarkAllAsRead = () => {
        markAllAsRead()
            .unwrap()
            .then(() => {
                enqueueSnackbar('All notifications mark as read', { variant: 'success' })
                refetch()
            })
            .catch((err: any) => {
                enqueueSnackbar(`An error occurred when updating: ${err?.data?.error}`, { variant: 'error' })
            })
    }

    const getNotificationsList = (anchor: Anchor) => {
        return (
            <Box className={classes.notificationsContainer} role="presentation">
                {items?.length > 0 ? (
                    items?.map(activity => (
                        <List key={activity.id}>
                            <ListItem key={activity.id}>
                                <NotificationRow
                                    company_name={activity.actor.company_name}
                                    title={activity?.target?.name || activity.title}
                                    fileName={activity?.action_object?.name}
                                    actionType={activity?.action_object?.type}
                                    timestamp={activity.timestamp}
                                    verb={activity.verb}
                                    icon={activity?.action_object?.name?.split('.').pop()}
                                    link={activity?.target?.external_url || ''}
                                    actor={activity.actor.username}
                                    actor_first_name={activity.actor.first_name}
                                    actor_last_name={activity.actor.last_name}
                                    info={activity.title}
                                    unread={activity.unread}
                                    id={activity.id}
                                    external_id={activity?.target?.external_id}
                                    subscriber_role={activity.subscriber_role}
                                    refetch={refetch}
                                />
                            </ListItem>

                            <Divider />
                        </List>
                    ))
                ) : (
                    <Typography variant="h3" style={{ textAlignLast: 'center', marginTop: '40px' }}>
                        No recent notifications
                    </Typography>
                )}
            </Box>
        )
    }

    const isUnreadNotifExist = items?.filter(item => item.unread === true)?.length > 0

    return (
        <div style={{ zIndex: 1000, right: 0, top: 200, position: 'absolute' }}>
            <React.Fragment key={'right'}>
                <Drawer className={classes.notificationsDrawer} anchor={'right'} open={props.isOpen} onClose={toggleDrawer('right', false)}>
                    <div className={classes.header}>
                        <Typography variant="h2" style={{ fontSize: 24 }}>
                            Notifications
                        </Typography>
                        <Box display="flex" gridColumnGap={24} className={classes.iconContainer}>
                            {' '}
                            <Button variant="outlined" disabled={!isUnreadNotifExist} onClick={() => submitMarkAllAsRead()}>
                                Mark All As Read
                            </Button>
                            <Box display="flex" gridColumnGap={18}>
                                <CloseIcon onClick={toggleDrawer('right', false)} />
                            </Box>
                        </Box>
                    </div>
                    <div className={classes.subheader}>
                        <SearchInput value={searched} onChange={searchVal => requestSearch(searchVal)} onClose={cancelSearch} />
                    </div>
                    <Divider />
                    {getNotificationsList('right')}
                    <Divider />
                    <Link component={RouterLink} to="/notifications">
                        <div className={classes.footer}>
                            <Typography variant="h2" style={{ fontSize: 16 }}>
                                View All Notifications <ChevronRightIcon />
                            </Typography>
                        </div>
                    </Link>
                </Drawer>
            </React.Fragment>
        </div>
    )
})
