import { createApi } from '@reduxjs/toolkit/query/react'

import http from 'utils/http'
import { IReduxOpportunity } from 'store/modules/opportunity/opportunity'
import { marketplaceApi } from '../marketplace/marketplace.query'
import { activityApiSlice } from '../activity/activity.query'

const reducerPath = 'opportunityApi'

export const opportunityApi = createApi({
    reducerPath,
    baseQuery: http(),
    tagTypes: ['DATA_ROOM', 'USER_ROLES', 'USER_INVITES', 'MY_OPPORTUNITIES', 'OPPORTUNITY_ACCESS'],
    endpoints: builder => ({
        getOpportunities: builder.query<IReduxOpportunity.IOpportunityResponse, IReduxOpportunity.IOpportunityParam>({
            query: arg => ({
                url: `/v1/Opportunities/${arg.type}`,
                method: 'GET',
                params: arg.params,
            }),
        }),

        getMyOpportunities: builder.query<IReduxOpportunity.IMyOpportunityResponse, Object | void>({
            query: () => ({
                url: `/v1/myopportunities`,
                method: 'GET',
            }),
            transformResponse: (response: IReduxOpportunity.IMyOpportunityResponse) => {
                const filteredTransactions = response.items.filter(transaction => transaction.status !== 'Archived')

                return {
                    limit: response.limit,
                    offset: response.offset,
                    more: response.more,
                    total: filteredTransactions.length,
                    items: filteredTransactions,
                }
            },
            providesTags: ['MY_OPPORTUNITIES'],
        }),
        toggleFavorite: builder.mutation({
            query: (contentItemId: string) => ({
                url: `/v1/Opportunities/${contentItemId}/favorite`,
                method: 'POST',
            }),
            async onQueryStarted(contentItemId, { dispatch, queryFulfilled }) {
                const patchOpportunities = dispatch(
                    opportunityApi.util.updateQueryData('getOpportunities', { type: '' }, draft => {
                        const opportunity = draft.items.find(item => item.contentItemId === contentItemId)
                        if (opportunity) opportunity.isFavourite = !opportunity.isFavourite
                    }),
                )

                const patchMyOpportunities = dispatch(
                    opportunityApi.util.updateQueryData('getMyOpportunities', {}, draft => {
                        const opportunity = draft.items.find(item => item.contentItemId === contentItemId)
                        if (opportunity) opportunity.isFavourite = !opportunity.isFavourite
                    }),
                )

                const patchOpportunityDetails = dispatch(
                    opportunityApi.util.updateQueryData('getOpportunityDetails', contentItemId, draft => {
                        if (draft) draft.isFavorite = !draft.isFavorite
                    }),
                )

                const patchOpportunityDetailsSummary = dispatch(
                    opportunityApi.util.updateQueryData('getOpportunityDetailsSummary', contentItemId, draft => {
                        draft.isFavourite = !draft.isFavourite
                    }),
                )

                const patchFavoirteMarketplace = dispatch(
                    marketplaceApi.util.updateQueryData('getFavouriteOpportunities', undefined, draft => {
                        draft.result[contentItemId] = !draft.result[contentItemId]
                    }),
                )

                try {
                    await queryFulfilled
                } catch {
                    patchOpportunities.undo()
                    patchOpportunityDetails.undo()
                    patchOpportunityDetailsSummary.undo()
                    patchMyOpportunities.undo()
                    patchFavoirteMarketplace.undo()
                }
            },
        }),
        getOpportunityDetails: builder.query<IReduxOpportunity.IOpportunityDetailsResponse, string>({
            query: (contentItemId: string) => ({
                url: `/v1/opportunities/${contentItemId}`,
                method: 'GET',
            }),
            extraOptions: { maxRetries: 1 },
        }),
        getOpportunity: builder.mutation<IReduxOpportunity.IOpportunityDetailsResponse, string>({
            query: (contentItemId: string) => ({
                url: `/v1/opportunities/${contentItemId}`,
                method: 'GET',
            }),
        }),
        getOpportunityDetailsSummary: builder.query<IReduxOpportunity.IOpportunityDetailsSummaryResponse, string>({
            query: (contentItemId: string) => ({
                url: `/v1/opportunities/${contentItemId}/summary`,
                method: 'GET',
            }),
        }),
        getDataIQ: builder.query<IReduxOpportunity.IOpportunityDataIQResponse, string>({
            query: (contentItemId: string) => ({
                url: `/v1/opportunities/${contentItemId}/dataiq`,
                method: 'GET',
            }),
        }),
        getUserRoleMembership: builder.query({
            query: (contentItemId: string) => ({
                url: `/v1/opportunities/${contentItemId}/user_roles/`,
                method: 'GET',
            }),
            providesTags: ['USER_ROLES'],
        }),
        getUserInvites: builder.query({
            query: () => ({
                url: `/v1/opportunities/invites/`,
                method: 'GET',
            }),
            providesTags: ['USER_INVITES'],
        }),
        createUserRoleMembership: builder.mutation({
            query: body => ({
                url: `/v1/opportunities/${body.contentItemId}/user_roles`,
                method: 'PUT',
                body: body.firstName
                    ? {
                          newUserEmail: body.newUserEmail,
                          role: body.role,
                          firstName: body.firstName,
                          lastName: body.lastName,
                          coreTeamId: body.coreTeamId,
                          roleType: body.roleTypes,
                      }
                    : {
                          newUserEmail: body.newUserEmail,
                          role: body.role,
                      },
            }),
            invalidatesTags: ['USER_ROLES'],
        }),
        updateUserRoleMembership: builder.mutation({
            query: body => ({
                url: `/v1/opportunities/${body.contentItemId}/user_roles/${body.roleId}/${body?.resendEmail ? `/?resendEmail=true` : ''}`,
                method: 'PATCH',
                body: body?.resendEmail
                    ? {}
                    : {
                          newUserEmail: body.newUserEmail,
                          role: body.role,
                          status: body.status,
                      },
            }),
            invalidatesTags: ['USER_ROLES', 'USER_INVITES', 'MY_OPPORTUNITIES'],
        }),
        deleteUserRoleMembership: builder.mutation({
            query: body => ({
                url: `/v1/opportunities/${body.contentItemId}/user_roles/${body.roleId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['USER_ROLES'],
        }),
        getOpportunityDataRoomStatus: builder.query<IReduxOpportunity.IOpportunityDataRoomResponse, string>({
            query: (contentItemId: string) => ({
                url: `/v1/opportunities/${contentItemId}/dataroom`,
                method: 'GET',
            }),
            providesTags: ['DATA_ROOM'],
        }),
        PostOpportunityDataRoomRequestAccess: builder.mutation({
            query: (contentItemId: string) => ({
                url: `/v1/opportunities/${contentItemId}/dataroom`,
                method: 'POST',
            }),
            invalidatesTags: ['DATA_ROOM'],
        }),
        PostOpportunityCreation: builder.mutation({
            query: (body: IReduxOpportunity.IOpportunityCreate) => ({
                url: '/v1/opportunities/',
                method: 'POST',
                body: {
                    Title: body.Title,
                    ProductTypeTermId: body.ProductTypeTermId,
                    StructureTermId: body.StructureTermId,
                    AssetClassTermId: body.AssetClassTermId,
                    currencyId: body.currencyId,
                    rank: body.rank,
                    estimatedMaturity: body.estimatedMaturity,
                    transactionSize: body.transactionSize,
                    Pricing: body.Pricing,
                    Status: body.Status,
                    Order: body.Order,
                    // IsPrivate: body.IsPrivate,
                    Geography: {
                        TermContentItemIds: body.Geography.TermContentItemIds,
                    },
                    PricingType: {
                        TermContentItemIds: body.PricingType.TermContentItemIds,
                    },
                    FloatingRateIndex: {
                        TermContentItemIds: body.FloatingRateIndex?.TermContentItemIds,
                    },
                },
            }),
        }),
        updateOpportunity: builder.mutation({
            query: (body: IReduxOpportunity.IOpportunityUpdate) => ({
                url: `/v1/opportunities/${body.contentItemId}`,
                method: 'PATCH',
                body: {
                    Title: body.Title,
                    ProductTypeTermId: body.ProductTypeTermId,
                    StructureTermId: body.StructureTermId,
                    AssetClassTermId: body.AssetClassTermId,
                    currencyId: body.currencyId,
                    rank: body.rank,
                    estimatedMaturity: body.estimatedMaturity,
                    transactionSize: body.transactionSize,
                    Pricing: body.Pricing,
                    transaction: body.transaction,
                    highlights: body.highlights,
                    footnotes: body.footnotes,
                    summary: body.summary,
                    isConfidential: body.isConfidential,
                    showDataIq: body.showDataIq,
                    showDdqToInvestor: body.showDdqToInvestor,
                    Order: body.Order,
                    // IsPrivate: body.IsPrivate,
                    Geography: {
                        TermContentItemIds: body.Geography.TermContentItemIds,
                    },
                    PricingType: {
                        TermContentItemIds: body.PricingType.TermContentItemIds,
                    },
                    FloatingRateIndex: {
                        TermContentItemIds: body.FloatingRateIndex?.TermContentItemIds,
                    },
                },
            }),
        }),
        updateOpportunityPublic: builder.mutation({
            query: (body: IReduxOpportunity.IOpportunityPublicUpdate) => ({
                url: `/v1/opportunities/${body.contentItemId}`,
                method: 'PATCH',
                body: {
                    IsPrivate: body.IsPrivate,
                },
            }),
        }),
        updateOpportunityPublish: builder.mutation({
            query: (body: IReduxOpportunity.IOpportunityPublishUpdate) => ({
                url: `/v1/opportunities/${body.contentItemId}`,
                method: 'PATCH',
                body: {
                    status: body.status,
                },
            }),
        }),
        uploadThumbnail: builder.mutation({
            query: (body: IReduxOpportunity.IOpportunityUploadThumbnail) => ({
                url: `/v1/opportunities/${body.contentItemId}/thumbnail`,
                method: 'POST',
                body: body.data,
            }),
        }),
        getOpportunityTeamExclusionList: builder.query({
            query: opportunityId => ({
                url: `/v1/opportunities/${opportunityId}/team_exclusion_list`,
                method: 'GET',
            }),
        }),
        getOpportunityAccess: builder.query({
            query: arg => ({
                url: `v1/opportunities/${arg.opportunityId}/access/`,
                method: 'GET',
                params: arg.params,
            }),
            providesTags: ['OPPORTUNITY_ACCESS'],
        }),
        createOpportunityAccess: builder.mutation({
            query: body => ({
                url: `v1/opportunities/${body.opportunityId}/company-access/${body.companyId}`,
                method: 'POST',
            }),
            invalidatesTags: ['OPPORTUNITY_ACCESS'],
        }),
        removeOpportunityAccess: builder.mutation({
            query: body => ({
                url: `v1/opportunities/${body.opportunityId}/company-access/${body.companyId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['OPPORTUNITY_ACCESS'],
        }),
        createUniversalInvite: builder.mutation({
            query: body => ({
                url: `/v1/inviteuser/`,
                method: 'POST',
                body: body,
            }),
            async onQueryStarted(args, { dispatch, queryFulfilled }) {
                await queryFulfilled
                setTimeout(() => dispatch(activityApiSlice.util.invalidateTags(['USER_LIST'])), 2000)
            },
        }),
        getComplianceApprove: builder.query<IReduxOpportunity.IOpportunityComplianceApproveResponse, { token }>({
            query: arg => ({
                url: `/v1/opportunities/complianceApprove?token=` + arg.token,
                method: 'GET',
            }),
        }),
        getComplianceResponse: builder.query<IReduxOpportunity.IOpportunityComplianceApproveResponse, { isApprove; token }>({
            query: arg => ({
                url: `/v1/opportunities/complianceResponse?isApprove=${arg.isApprove}&token=${arg.token}`,
                method: 'GET',
            }),
        }),
        getWhitelabelResponse: builder.query<IReduxOpportunity.IOpportunityWhitelabelApproveResponse, { isApprove; token }>({
            query: arg => ({
                url: `/v1/commercialentity/whitelabelResponse?approve=${arg.isApprove}&token=${arg.token}`,
                method: 'GET',
            }),
        }),
    }),
})

export const {
    useGetOpportunitiesQuery,
    useGetMyOpportunitiesQuery,
    useToggleFavoriteMutation,
    useGetDataIQQuery,
    useGetOpportunityDetailsSummaryQuery,
    useGetOpportunityDetailsQuery,
    useGetOpportunityDataRoomStatusQuery,
    usePostOpportunityDataRoomRequestAccessMutation,
    useGetOpportunityMutation,
    usePostOpportunityCreationMutation,
    useUpdateOpportunityMutation,
    useUpdateOpportunityPublicMutation,
    useUpdateOpportunityPublishMutation,
    useUploadThumbnailMutation,
    useGetOpportunityTeamExclusionListQuery,
    useGetUserRoleMembershipQuery,
    useCreateUserRoleMembershipMutation,
    useDeleteUserRoleMembershipMutation,
    useUpdateUserRoleMembershipMutation,
    useGetUserInvitesQuery,
    useCreateUniversalInviteMutation,
    useGetOpportunityAccessQuery,
    useCreateOpportunityAccessMutation,
    useRemoveOpportunityAccessMutation,
    useGetComplianceApproveQuery,
    useGetComplianceResponseQuery,
    useGetWhitelabelResponseQuery,
} = opportunityApi

export const opportunityQueryReducer = { [reducerPath]: opportunityApi.reducer }
export const opportunityQuerySelector = state => state[reducerPath]
